export default {
  environment: 'qa',
  production: false,
  TAX_PERCENTAGE: 15,
  GOOGLE_KEY: 'AIzaSyDyKLC3b_LgJKU-vbBwm1sWTm80ir50074',
  MAIN_DOMAIN: 'qa.naeeman.com',
  PORTAL_URL: 'https://qa.grow.naeeman.com',
  JWT_SECRET_KEY: 'cc7e0d44fd473002f1c42167459001140ec6389b7353f8088f4d9a95f2f596f2',

  s3: {
    REGION: 'us-east-1',
    BUCKET: 'naeeman-qa-media-bucket',
    BUCKET_URL: 'https://naeeman-qa-media-bucket.s3.amazonaws.com/public/',
  },
  API: {
    REGION: 'us-east-1',
    URL: 'https://api.naeeman.com/v1-qa',
    // URL: 'http://localhost:3333/v1',
  },
  NOTIFICATION_API: {
    REGION: 'us-east-1',
    URL: 'https://api.naeeman.com/n1-qa',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_z12vuwiAK',
    APP_CLIENT_ID: '887i7mdudi8s0p95mn2tutekm',
    IDENTITY_POOL_ID: 'us-east-1:d623faeb-15ee-4d69-becc-d72ddd6da79b',
  },
  APPSYNC: {
    GRAPHQL_URL: 'https://rmpdjstj7vgzrlnvjbvfzgjuhe.appsync-api.us-east-1.amazonaws.com/graphql',
    REGION: 'us-east-1',
    AUTH_TYPE: 'API_KEY',
    API_KEY: 'da2-qhkj5pea3vdu7grg27kjlz7gya',
  },
};
